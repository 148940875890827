<template>
    <v-container v-if="item" fluid class="pa-0">
        <v-row>
            <DashboardCommunityReportItemHeader
                :item="item"
                @addTask="
                    createTask({
                        title: item.heading,
                        type: 'feed',
                        entityId: item.id,
                        feedType: item.category,
                    })
                "
            />
            <v-col cols="12">
                <v-card flat color="transparent">
                    <v-card-title class="pa-0 pt-1">
                        <router-link
                            :to="{
                                name: 'CommunityList',
                                params: {
                                    category: item.category,
                                    id: item.id,
                                },
                            }"
                            :style="reportLinkStyleObject"
                        >
                            <span
                                class="text-h5 primary--text text--lighten-1 font-weight-thin text-wrap"
                                >{{ item.heading }}</span
                            >
                        </router-link>
                    </v-card-title>
                    <v-card-text class="pa-0 pt-3">
                        <div
                            class="text-wrap"
                            :class="{ 'grey--text': $vuetify.dark }"
                        >
                            <router-link
                                :style="reportLinkStyleObject"
                                :to="{
                                    name: 'CommunityList',
                                    params: {
                                        category: item.category,
                                        id: item.id,
                                    },
                                }"
                                >{{ $htmlSnippet(textContent, 320) }}</router-link
                            >
                        </div>
                        <template v-if="false">
                            <div
                                v-for="(attachment, j) in item.attachments"
                                :key="j"
                            >
                                <v-icon>attachment</v-icon>&nbsp;<a
                                    class="attachment-link text-wrap"
                                    :href="attachment.attachment.url"
                                    target="_blank"
                                    >{{ attachment.attachment.name }} | 
                                    {{ $humaniseFileSize(attachment.attachment.size) }}
                                </a>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
                <v-divider
                    class="mt-6 mb-3"
                    v-if="i + 1 !== itemsLength"
                ></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import DashboardCommunityReportItemHeader from '@/components/dashboard/DashboardCommunity/DashboardCommunityReportItemHeader';

export default {
    props: {
        item: Object,
        i: Number,
        itemsLength: Number,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    components: {
        DashboardCommunityReportItemHeader,
    },
    data() {
        return {};
    },
    computed: {
        textContent() {
            if (this.item.summary && this.item.summary.length > 8) {
                return this.item.summary;
            }
            return this.item.content;
        },
        reportLinkStyleObject() {
            const styleObject = {
                color: this.$vuetify.dark ? '#fff' : 'rgba(0,0,0,.87)',
                textDecoration: 'none',
            };
            return styleObject;
        },
    },
    methods: {
        ...mapActions({
            createTask: 'dashboard/createContactTask',
        })
    },
};
</script>
